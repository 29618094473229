@import "variables";

main {
  padding-top: 141px;
  position: relative;
}

.home-carousel .slick-dots {
  bottom: 1.2rem !important;
}

.slick-dots,
.slick-dots li {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots li{
    height: 12px!important;
    width: 12px!important;
}

.slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  border: 2px solid $color-primary !important;
  border-radius: 9999px !important;
}
.slick-dots li.slick-active button {
  background-color: $color-primary !important;
}

.slick-dots li button:before {
  content: "" !important;
}

.text {
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
}

.title {
  margin-bottom: 70px;
  padding-left: 16px;
  border-left: 2px solid $color-primary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .right {
    display: flex;
    flex-flow: row;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
  }

  .btn {
    margin-left: 16px;
  }
}

.btn {
  display: inline-block;
  height: 44px;
  border-radius: 22px;
  margin: 0;
  padding: 0 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 44px;

  &-default {
    background: $color-primary;
    color: $color-dark;

    &:hover {
      background: $color-secondary;
      color: $color-primary;
    }
  }

  &-secondary {
    background: $color-secondary;

    &:hover {
      background: #323232;
    }
  }

  &-outlined {
    border: 1px solid $color-primary;

    &:hover {
      background: $color-primary;
      color: $color-dark;
    }

    &--light {
      color: $color-dark;
    }
  }

  &-icon {
    display: inline-flex;
    align-items: center;

    .fas,
    .far {
      margin-right: 8px;
    }
  }

  &-input-icon {
    height: 44px;
    width: 44px;
    line-height: 44px;
    padding: 0;
    border-radius: 50%;
    background: #ffffff;
    text-align: center;
    color: $color-primary;
    display: block;

    &:hover {
      background: #f7f7f7;
    }
  }

  &-small {
    height: 38px;
    border-radius: 19px;
    line-height: 38px;
  }

  &-large {
    height: 56px;
    padding: 0 40px;
    line-height: 56px;
    border-radius: 28px;
    font-size: 17px;
  }

  &.block {
    display: block;
    width: 100%;
    text-align: center;
  }

  &:hover {
    text-decoration: none;
  }
}

// banner
.swiper-pagination {
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border: 2px solid $color-primary;
    background: transparent;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: $color-primary;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
// end banner

// container
.container {
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1600px;
  }
}
// end container

// form
.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  &-control {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 16px;

    input {
      width: 100%;
      height: 50px;
      border-bottom: 1px solid $color-secondary;
      padding: 0 16px;
      background: transparent;
      font-size: 16px;
      border-radius: 0px;
      background: #f7f7f7;
      transition: all 300ms;

      &:focus {
        border-color: $color-primary;
        background: #e7e7e7;
      }
    }

    textarea {
      width: 100%;
      min-height: 120px;
      border-bottom: 1px solid $color-secondary;
      padding: 16px;
      background: transparent;
      font-size: 16px;
      resize: none;
      border-radius: 0px;
      background: #f7f7f7;
      transition: all 300ms;

      &:focus {
        border-color: $color-primary;
        background: #e7e7e7;
      }
    }

    select {
      width: 100%;
      height: 50px;
      border: 0;
      border-bottom: 1px solid $color-secondary;
      padding: 0 16px;
      background: transparent;
      font-size: 16px;
      border-radius: 0px;
      outline: 0;
      -webkit-appearance: none;
      appearance: none;
      background: #f7f7f7;
      transition: all 300ms;
    }

    &__error {
      font-size: 12px;
      font-weight: 700;
      color: $color-danger;
    }
  }

  .radio-group {
    margin-bottom: 20px;
    &-title {
      font-size: 15px;
      display: block;
      margin-bottom: 8px;
    }
  }
  .radio-item {
    display: inline-block;
    margin-right: 16px;
    input {
      display: none;

      &:checked {
        & + label {
          &::before {
            background: $color-primary;
          }
        }
      }
    }
    label {
      position: relative;
      padding-left: 24px;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 14px;
        width: 14px;
        border-radius: 50%;
        border: 2px solid $color-primary;
      }
    }
  }

  button {
    margin-left: auto;
  }
}
// end form

// portfolio
.portfolio {
  &-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    &-button {
      text-align: center;
      margin-top: 20px;
      .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &-item {
    flex: 0 0 49%;
    width: 49%;
    position: relative;
    min-height: 340px;
    overflow: hidden;
    margin-bottom: 20px;

    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        height: 340px;
        width: 100%;
        object-fit: cover;
        display: block;
        transition: all 300ms ease;
      }
    }

    &-label {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      z-index: 2;
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      padding: 10%;
      opacity: 0;
      color: $color-dark;
      transition: all 300ms ease;
    }

    &:hover {
      .portfolio-item {
        &-image {
          img {
            transform: scale(1.1);
          }
        }
        &-label {
          transform: translate(-50%, -50%);
          opacity: 1;
        }
      }

      &::after {
        height: 100%;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0);
      width: 100%;
      height: 20px;
      transition: all 300ms ease;
      z-index: 1;
      background: $color-primary;
      opacity: 0.6;
    }
  }
}
// end portfolio

// blog
.blog {
  &-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;

    &-add {
      margin-top: 40px;
    }
  }

  &-item {
    flex: 0 0 31.33%;
    width: 31.33%;
    background: #f7f7f7;
    border-bottom: 2px solid $color-secondary;

    &-header {
      position: relative;
      height: 240px;
      overflow: hidden;
    }

    &-tag {
      position: absolute;
      bottom: 0;
      right: 16px;
      background: #ffffff;
      padding: 4px 8px;
      font-weight: 700;
      font-size: 12px;
      color: $color-dark;
      text-transform: uppercase;
      background: $color-primary;
    }

    &-image {
      min-height: 240px;
      position: relative;
      overflow: hidden;

      img {
        height: 240px;
        width: 100%;
        object-fit: cover;
        transition: all 500ms;
        display: block;
      }
    }

    &-content {
      padding: 24px;
    }

    &-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
      color: $color-secondary;
    }

    &-description {
      color: #323232;
    }

    &-link {
      display: flex;
      align-items: center;
      color: $color-secondary;
      margin-top: 32px;

      .fas {
        margin-left: 8px;
        transition: all 300ms ease-in-out;
      }
    }

    &:hover {
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

      .blog-item {
        &-image {
          img {
          }
        }

        &-link {
          .fas {
            margin-left: 16px;
          }
        }
      }
    }

    &:nth-child(3n-1) {
      margin-left: 3%;
      margin-right: 3%;
    }

    &.blog-item--full {
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;

      .blog-item {
        &-header {
          flex: 0 0 40%;
          width: 40%;
          height: 100%;
        }

        &-image {
          height: 100%;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 500ms;
          }
        }
      }

      & + .blog-item {
        margin-left: 0px;
        margin-top: 60px;
      }
    }
  }
}
// end blog

.social-sharing {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 999;

  &__item {
    display: block;

    a {
      height: 44px;
      width: 44px;
      line-height: 44px;
      text-align: center;
      display: inline-block;
      color: #ffffff;

      &:hover {
        width: 56px;
      }
    }
    &--facebook {
      a {
        background: #3b5998;
      }
    }

    &--twitter {
      a {
        background: #1da1f2;
      }
    }

    &--linkedin {
      a {
        background: #0077b5;
      }
    }

    &--mail {
      a {
        background: #239ab9;
      }
    }
  }
}

// job
// blog
.job {
  &-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;

    &-button {
      text-align: center;
      margin-top: 20px;
      .btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &-item {
    flex: 0 0 31.33%;
    width: 31.33%;
    background: #ffffff;
    border-bottom: 2px solid $color-secondary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;

    &-header {
      position: relative;
      height: 240px;
      overflow: hidden;
    }

    &-tag {
      position: absolute;
      bottom: 0;
      right: 16px;
      background: #ffffff;
      padding: 4px 8px;
      font-weight: 700;
      font-size: 12px;
      color: $color-dark;
      text-transform: uppercase;
      background: $color-primary;
    }

    img {
      height: 240px;
      width: 100%;
      object-fit: cover;
      transition: all 500ms;
      display: block;
      transition: all 500ms;
    }

    &-content {
      padding: 24px;
    }

    &-footer {
      padding: 16px 24px;
      border-top: 1px solid #ccc;
      ul {
        li {
          display: inline-flex;
          align-items: center;
          margin-right: 16px;
          font-size: 13px;

          .fas {
            margin-right: 8px;
          }
        }
      }
    }

    &-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 8px;
      color: $color-secondary;
    }

    &-description {
      color: #323232;
    }

    &:hover {
      text-decoration: none;
      transform: translateY(-10px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);

      .job-item {
        &-header {
          img {
            transform: scale(1.103);
          }
        }
      }
    }

    &:nth-child(3n-1) {
      margin-left: 3%;
      margin-right: 3%;
    }

    &.blog-item--full {
      flex: 0 0 100%;
      width: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;

      .blog-item {
        &-header {
          flex: 0 0 40%;
          width: 40%;
          height: 100%;
        }

        &-image {
          min-height: 100%;
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            object-fit: cover;
            transition: all 500ms;
          }
        }
      }

      & + .blog-item {
        margin-left: 0px;
        margin-top: 60px;
      }
    }
  }
}
// end job

// toggle
.toggle {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  &-item {
    input[type="radio"] {
      display: none;

      &:checked + label {
        background: $color-primary;
        color: rgba(18, 18, 18, 1);
      }
    }

    label {
      display: inline-block;
      height: 44px;
      line-height: 44px;
      border: 1px solid $color-primary;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: rgba(18, 18, 18, 0.5);
      border-radius: 22px 0 0 22px;
      padding: 0 24px;
      transition: all 200ms ease;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
        color: rgba(18, 18, 18, 1);
      }
    }

    & + .toggle-item {
      position: relative;
      left: -8px;

      label {
        border-radius: 0 22px 22px 0;
      }
    }
  }
}
// end toggle

@media screen and (max-width: 768px) {
  main {
    padding-top: 64px;
  }

  .title {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 40px;

    h2 {
      font-size: 32px;
      text-align: center;
      display: block;
      width: 100%;
    }

    .btn {
      margin: 16px auto 0;
    }

    .right {
      flex-flow: column;

      .btn.btn-icon {
        .fas {
          margin-left: 8px;
          margin-right: 0px;
        }
      }
    }

    .toggle {
      margin-top: 16px;
    }
  }

  // portfolio
  .portfolio {
    &-item {
      flex: 0 0 100%;
      width: 100%;
      min-height: 140px;

      &-image {
        img {
          height: 140px;
        }
      }
    }
  }
  // end portfolio

  .blog {
    &-item {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 36px;

      &:nth-child(3n-1) {
        margin-left: 0;
        margin-right: 0;
      }

      &.blog-item--full {
        flex-flow: wrap;
        .blog-item {
          &-header {
            flex: 0 0 100%;
            width: 100%;
            min-height: 120px;
            height: auto;
          }

          &-image {
            min-height: 120px;

            img {
              height: 120px;
            }
          }
        }

        & + .blog-item {
          margin-top: 0px;
        }
      }
    }
  }

  .job {
    &-item {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 36px;

      &:nth-child(3n-1) {
        margin-left: 0;
        margin-right: 0;
      }

      &-header {
        flex: 0 0 100%;
        width: 100%;
        min-height: 160px;
        height: auto;

        img {
          height: 160px;
        }
      }
    }
  }

  .social-sharing {
    top: unset;
    bottom: 0;
    transform: unset;
    display: flex;
    flex-flow: row;
    align-items: stretch;
    width: 100%;
    z-index: 10;

    &__item {
      flex: 1;

      a {
        width: 100%;
      }
    }
  }
}
