@import "variables";

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
    margin: 0;
    padding: 0;
}
h3,
h4,
h5,
h6 {
    font-weight: 700;
}
h1,
h2 {
    font-weight: 700;
}
small {
    font-weight: 400;
    font-size: 12px;
}
html {
    -webkit-font-smoothing: antialiased;
    font-size: 100%;
    font-family: "Roboto Slab", sans-serif;
}
strong,
b {
    font-weight: 700;
}
p {
    color: currentColor;
    font-size: inherit;
    line-height: 1.5;
}
blockquote {
    font-size: 14px;
    font-style: italic;
    padding-left: 40px;
    border-left: 1px solid rgba(18, 18, 18, 0.2);
}
* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul,
ol {
    list-style: none;
}
a {
    text-decoration: none;
    color: currentColor;
}
a:hover {
    text-decoration: underline;
}
a,
button {
    transition: all 300ms ease-in-out;
    font: inherit;
}
a:hover,
button:hover {
    cursor: pointer;
}
input,
textarea,
button {
    outline: none;
    border: 0;
    background: transparent;
}
body {
    color: $text-color-primary;
    overflow-x: hidden;
    background: #ffffff;
}
.clear {
    &::before,
    &::after {
        content: " ";
        display: table;
    }

    &::after {
        clear: both;
    }
}
img {
    max-width: 100%;
    height: auto;
    word-break: break-all;
}
input,
textarea,
select {
    color: inherit;
    font-family: inherit;

    &::placeholder {
        color: currentColor;
        opacity: 0.85;
    }
}
table {
    width: 100%;
}
::selection {
    background: #ccc;
    color: #fff;
}
.summernote {
    font-size: 1.13rem;
    line-height: 2;
    font-weight: 300;
    & > * {
        display: block;
        width: 100%;
        max-width: 100%;
        margin: 0 auto 3px;
    }
}

@media screen and (min-width: 800px) {
    .hidden-desktop {
        display: none !important;
    }
}
