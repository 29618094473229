body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
th,
td {
  margin: 0;
  padding: 0;
}

h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2 {
  font-weight: 700;
}

small {
  font-weight: 400;
  font-size: 12px;
}

html {
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  font-family: "Roboto Slab", sans-serif;
}

strong,
b {
  font-weight: 700;
}

p {
  color: currentColor;
  font-size: inherit;
  line-height: 1.5;
}

blockquote {
  font-size: 14px;
  font-style: italic;
  padding-left: 40px;
  border-left: 1px solid rgba(18, 18, 18, 0.2);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  text-decoration: underline;
}

a,
button {
  transition: all 300ms ease-in-out;
  font: inherit;
}

a:hover,
button:hover {
  cursor: pointer;
}

input,
textarea,
button {
  outline: none;
  border: 0;
  background: transparent;
}

body {
  color: #545453;
  overflow-x: hidden;
  background: #ffffff;
}

.clear::before, .clear::after {
  content: " ";
  display: table;
}
.clear::after {
  clear: both;
}

img {
  max-width: 100%;
  height: auto;
  word-break: break-all;
}

input,
textarea,
select {
  color: inherit;
  font-family: inherit;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: currentColor;
  opacity: 0.85;
}

table {
  width: 100%;
}

::selection {
  background: #ccc;
  color: #fff;
}

.summernote {
  font-size: 1.13rem;
  line-height: 2;
  font-weight: 300;
}
.summernote > * {
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto 3px;
}

@media screen and (min-width: 800px) {
  .hidden-desktop {
    display: none !important;
  }
}
main {
  padding-top: 141px;
  position: relative;
}

.home-carousel .slick-dots {
  bottom: 1.2rem !important;
}

.slick-dots,
.slick-dots li {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-dots li {
  height: 12px !important;
  width: 12px !important;
}

.slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  border: 2px solid #fff200 !important;
  border-radius: 9999px !important;
}

.slick-dots li.slick-active button {
  background-color: #fff200 !important;
}

.slick-dots li button:before {
  content: "" !important;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.title {
  margin-bottom: 70px;
  padding-left: 16px;
  border-left: 2px solid #fff200;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title .right {
  display: flex;
  flex-flow: row;
}
.title h2 {
  font-size: 32px;
  font-weight: 700;
}
.title .btn {
  margin-left: 16px;
}

.btn {
  display: inline-block;
  height: 44px;
  border-radius: 22px;
  margin: 0;
  padding: 0 20px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  line-height: 44px;
}
.btn-default {
  background: #fff200;
  color: #000000;
}
.btn-default:hover {
  background: #545453;
  color: #fff200;
}
.btn-secondary {
  background: #545453;
}
.btn-secondary:hover {
  background: #323232;
}
.btn-outlined {
  border: 1px solid #fff200;
}
.btn-outlined:hover {
  background: #fff200;
  color: #000000;
}
.btn-outlined--light {
  color: #000000;
}
.btn-icon {
  display: inline-flex;
  align-items: center;
}
.btn-icon .fas,
.btn-icon .far {
  margin-right: 8px;
}
.btn-input-icon {
  height: 44px;
  width: 44px;
  line-height: 44px;
  padding: 0;
  border-radius: 50%;
  background: #ffffff;
  text-align: center;
  color: #fff200;
  display: block;
}
.btn-input-icon:hover {
  background: #f7f7f7;
}
.btn-small {
  height: 38px;
  border-radius: 19px;
  line-height: 38px;
}
.btn-large {
  height: 56px;
  padding: 0 40px;
  line-height: 56px;
  border-radius: 28px;
  font-size: 17px;
}
.btn.block {
  display: block;
  width: 100%;
  text-align: center;
}
.btn:hover {
  text-decoration: none;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border: 2px solid #fff200;
  background: transparent;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff200;
}
.swiper-pagination .swiper-pagination-bullet:hover {
  cursor: pointer;
}

.container {
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1600px;
  }
}
.form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.form-control {
  flex: 0 0 100%;
  width: 100%;
  margin-bottom: 16px;
}
.form-control input {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #545453;
  padding: 0 16px;
  background: transparent;
  font-size: 16px;
  border-radius: 0px;
  background: #f7f7f7;
  transition: all 300ms;
}
.form-control input:focus {
  border-color: #fff200;
  background: #e7e7e7;
}
.form-control textarea {
  width: 100%;
  min-height: 120px;
  border-bottom: 1px solid #545453;
  padding: 16px;
  background: transparent;
  font-size: 16px;
  resize: none;
  border-radius: 0px;
  background: #f7f7f7;
  transition: all 300ms;
}
.form-control textarea:focus {
  border-color: #fff200;
  background: #e7e7e7;
}
.form-control select {
  width: 100%;
  height: 50px;
  border: 0;
  border-bottom: 1px solid #545453;
  padding: 0 16px;
  background: transparent;
  font-size: 16px;
  border-radius: 0px;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  background: #f7f7f7;
  transition: all 300ms;
}
.form-control__error {
  font-size: 12px;
  font-weight: 700;
  color: #c34b47;
}
.form .radio-group {
  margin-bottom: 20px;
}
.form .radio-group-title {
  font-size: 15px;
  display: block;
  margin-bottom: 8px;
}
.form .radio-item {
  display: inline-block;
  margin-right: 16px;
}
.form .radio-item input {
  display: none;
}
.form .radio-item input:checked + label::before {
  background: #fff200;
}
.form .radio-item label {
  position: relative;
  padding-left: 24px;
}
.form .radio-item label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #fff200;
}
.form button {
  margin-left: auto;
}

.portfolio-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.portfolio-list-button {
  text-align: center;
  margin-top: 20px;
}
.portfolio-list-button .btn {
  margin-left: auto;
  margin-right: auto;
}
.portfolio-item {
  flex: 0 0 49%;
  width: 49%;
  position: relative;
  min-height: 340px;
  overflow: hidden;
  margin-bottom: 20px;
}
.portfolio-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.portfolio-item-image img {
  height: 340px;
  width: 100%;
  object-fit: cover;
  display: block;
  transition: all 300ms ease;
}
.portfolio-item-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: 2;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  padding: 10%;
  opacity: 0;
  color: #000000;
  transition: all 300ms ease;
}
.portfolio-item:hover .portfolio-item-image img {
  transform: scale(1.1);
}
.portfolio-item:hover .portfolio-item-label {
  transform: translate(-50%, -50%);
  opacity: 1;
}
.portfolio-item:hover::after {
  height: 100%;
}
.portfolio-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  width: 100%;
  height: 20px;
  transition: all 300ms ease;
  z-index: 1;
  background: #fff200;
  opacity: 0.6;
}

.blog-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}
.blog-list-add {
  margin-top: 40px;
}
.blog-item {
  flex: 0 0 31.33%;
  width: 31.33%;
  background: #f7f7f7;
  border-bottom: 2px solid #545453;
}
.blog-item-header {
  position: relative;
  height: 240px;
  overflow: hidden;
}
.blog-item-tag {
  position: absolute;
  bottom: 0;
  right: 16px;
  background: #ffffff;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  text-transform: uppercase;
  background: #fff200;
}
.blog-item-image {
  min-height: 240px;
  position: relative;
  overflow: hidden;
}
.blog-item-image img {
  height: 240px;
  width: 100%;
  object-fit: cover;
  transition: all 500ms;
  display: block;
}
.blog-item-content {
  padding: 24px;
}
.blog-item-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #545453;
}
.blog-item-description {
  color: #323232;
}
.blog-item-link {
  display: flex;
  align-items: center;
  color: #545453;
  margin-top: 32px;
}
.blog-item-link .fas {
  margin-left: 8px;
  transition: all 300ms ease-in-out;
}
.blog-item:hover {
  text-decoration: none;
  transform: translateY(-10px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.blog-item:hover .blog-item-link .fas {
  margin-left: 16px;
}
.blog-item:nth-child(3n-1) {
  margin-left: 3%;
  margin-right: 3%;
}
.blog-item.blog-item--full {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.blog-item.blog-item--full .blog-item-header {
  flex: 0 0 40%;
  width: 40%;
  height: 100%;
}
.blog-item.blog-item--full .blog-item-image {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.blog-item.blog-item--full .blog-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 500ms;
}
.blog-item.blog-item--full + .blog-item {
  margin-left: 0px;
  margin-top: 60px;
}

.social-sharing {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 999;
}
.social-sharing__item {
  display: block;
}
.social-sharing__item a {
  height: 44px;
  width: 44px;
  line-height: 44px;
  text-align: center;
  display: inline-block;
  color: #ffffff;
}
.social-sharing__item a:hover {
  width: 56px;
}
.social-sharing__item--facebook a {
  background: #3b5998;
}
.social-sharing__item--twitter a {
  background: #1da1f2;
}
.social-sharing__item--linkedin a {
  background: #0077b5;
}
.social-sharing__item--mail a {
  background: #239ab9;
}

.job-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
}
.job-list-button {
  text-align: center;
  margin-top: 20px;
}
.job-list-button .btn {
  margin-left: auto;
  margin-right: auto;
}
.job-item {
  flex: 0 0 31.33%;
  width: 31.33%;
  background: #ffffff;
  border-bottom: 2px solid #545453;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}
.job-item-header {
  position: relative;
  height: 240px;
  overflow: hidden;
}
.job-item-tag {
  position: absolute;
  bottom: 0;
  right: 16px;
  background: #ffffff;
  padding: 4px 8px;
  font-weight: 700;
  font-size: 12px;
  color: #000000;
  text-transform: uppercase;
  background: #fff200;
}
.job-item img {
  height: 240px;
  width: 100%;
  object-fit: cover;
  transition: all 500ms;
  display: block;
  transition: all 500ms;
}
.job-item-content {
  padding: 24px;
}
.job-item-footer {
  padding: 16px 24px;
  border-top: 1px solid #ccc;
}
.job-item-footer ul li {
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
  font-size: 13px;
}
.job-item-footer ul li .fas {
  margin-right: 8px;
}
.job-item-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #545453;
}
.job-item-description {
  color: #323232;
}
.job-item:hover {
  text-decoration: none;
  transform: translateY(-10px);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}
.job-item:hover .job-item-header img {
  transform: scale(1.103);
}
.job-item:nth-child(3n-1) {
  margin-left: 3%;
  margin-right: 3%;
}
.job-item.blog-item--full {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
}
.job-item.blog-item--full .blog-item-header {
  flex: 0 0 40%;
  width: 40%;
  height: 100%;
}
.job-item.blog-item--full .blog-item-image {
  min-height: 100%;
  position: relative;
  overflow: hidden;
}
.job-item.blog-item--full .blog-item-image img {
  width: 100%;
  object-fit: cover;
  transition: all 500ms;
}
.job-item.blog-item--full + .blog-item {
  margin-left: 0px;
  margin-top: 60px;
}

.toggle {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}
.toggle-item input[type=radio] {
  display: none;
}
.toggle-item input[type=radio]:checked + label {
  background: #fff200;
  color: rgb(18, 18, 18);
}
.toggle-item label {
  display: inline-block;
  height: 44px;
  line-height: 44px;
  border: 1px solid #fff200;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: rgba(18, 18, 18, 0.5);
  border-radius: 22px 0 0 22px;
  padding: 0 24px;
  transition: all 200ms ease;
  white-space: nowrap;
}
.toggle-item label:hover {
  cursor: pointer;
  color: rgb(18, 18, 18);
}
.toggle-item + .toggle-item {
  position: relative;
  left: -8px;
}
.toggle-item + .toggle-item label {
  border-radius: 0 22px 22px 0;
}

@media screen and (max-width: 768px) {
  main {
    padding-top: 64px;
  }
  .title {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .title h2 {
    font-size: 32px;
    text-align: center;
    display: block;
    width: 100%;
  }
  .title .btn {
    margin: 16px auto 0;
  }
  .title .right {
    flex-flow: column;
  }
  .title .right .btn.btn-icon .fas {
    margin-left: 8px;
    margin-right: 0px;
  }
  .title .toggle {
    margin-top: 16px;
  }
  .portfolio-item {
    flex: 0 0 100%;
    width: 100%;
    min-height: 140px;
  }
  .portfolio-item-image img {
    height: 140px;
  }
  .blog-item {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 36px;
  }
  .blog-item:nth-child(3n-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .blog-item.blog-item--full {
    flex-flow: wrap;
  }
  .blog-item.blog-item--full .blog-item-header {
    flex: 0 0 100%;
    width: 100%;
    min-height: 120px;
    height: auto;
  }
  .blog-item.blog-item--full .blog-item-image {
    min-height: 120px;
  }
  .blog-item.blog-item--full .blog-item-image img {
    height: 120px;
  }
  .blog-item.blog-item--full + .blog-item {
    margin-top: 0px;
  }
  .job-item {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 36px;
  }
  .job-item:nth-child(3n-1) {
    margin-left: 0;
    margin-right: 0;
  }
  .job-item-header {
    flex: 0 0 100%;
    width: 100%;
    min-height: 160px;
    height: auto;
  }
  .job-item-header img {
    height: 160px;
  }
  .social-sharing {
    top: unset;
    bottom: 0;
    transform: unset;
    display: flex;
    flex-flow: row;
    align-items: stretch;
    width: 100%;
    z-index: 10;
  }
  .social-sharing__item {
    flex: 1;
  }
  .social-sharing__item a {
    width: 100%;
  }
}